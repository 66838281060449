import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPanelLanguage, LazyAccountMenu, LazyThemeToggle, LazyWinxLogo, LazyWinxToaster, LazyCircularMenuActivity, LazyCircularMenuLanguage, LazyCircularMenuNotifications, LazySubsidebarDashboards, LazySubsidebarLayouts, LazyToolbarAccountMenu, LazyToolbarActivity, LazyToolbarLanguage, LazyToolbarNotifications, LazyToolbarSearch, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["PanelLanguage", LazyPanelLanguage],
["AccountMenu", LazyAccountMenu],
["ThemeToggle", LazyThemeToggle],
["WinxLogo", LazyWinxLogo],
["WinxToaster", LazyWinxToaster],
["CircularMenuActivity", LazyCircularMenuActivity],
["CircularMenuLanguage", LazyCircularMenuLanguage],
["CircularMenuNotifications", LazyCircularMenuNotifications],
["SubsidebarDashboards", LazySubsidebarDashboards],
["SubsidebarLayouts", LazySubsidebarLayouts],
["ToolbarAccountMenu", LazyToolbarAccountMenu],
["ToolbarActivity", LazyToolbarActivity],
["ToolbarLanguage", LazyToolbarLanguage],
["ToolbarNotifications", LazyToolbarNotifications],
["ToolbarSearch", LazyToolbarSearch],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
