export default defineI18nLocale(async () => {
  return {
    base: {
      privacy: 'Al usar las soluciones de Winx, acepta nuestra {privacyPolicy}.',
      privacyPolicy: 'Política de privacidad',
    },
    titleBase: 'Encuesta Winx',
    _pages: {
      _login: {
        _step1: {
          title: 'Encuesta',
          subtitle: 'Para comenzar, seleccione el formulario de acceso proporcionado por su empresa.',
          _optionEmail: {
            title: 'Acceso por correo electrónico',
            description: 'Utilice su correo electrónico de trabajo',
          },
          _optionPhone: {
            title: 'Acceso por SMS',
            description: 'Utilice su teléfono de trabajo',
          },
          _optionDoc: {
            title: 'Acceso por CPF',
            description: 'Utilice su documento',
          },
        },
        _step2: {
          title: 'Acceso por @:{option}',
          subtitleOtp: 'Ingrese su @:{option} de trabajo para recibir un código de acceso único.',
          subtitle: 'Ingrese su documento para acceder.',
          placeholderEmail: '@:wb.email de trabajo',
          placeholderPhone: '@:wb.phone de trabajo',
          placeholderDoc: '@:wb.document',
        },
        _step3: {
          title: 'Código de verificación',
          subtitle: 'Enviamos un código de 6 dígitos a su @:{option} de trabajo.',
          resendCode: '¿No recibió el código?',
          resendByEmail: 'Enviar nuevo código por correo electrónico',
          resendByPhone: 'Enviar nuevo código por SMS',
        },
        changeOfAccess: '¿No es la forma de acceso correcta?',
        support: 'Si no encuentras un mensaje de Winnie con el código, revisa tu carpeta de spam. Puede que esté allí.',
        comments: 'Si necesitas ayuda, simplemente contáctanos a través de nuestro {helpCenter}.',
        footer: 'Winx - Ampliar la conciencia para la auténtica felicidad.',
        title: 'Acceso unificado',
        resendingAccess: 'Un nuevo código de acceso ha sido enviado a su @:{channel}.',
      },
      _index: {
        _header: {
          title: 'Hola, {name}. ¡Qué bueno verte por aquí!',
          description:
            'Este espacio {private} y {encrypted} te permite seleccionar las encuestas que deseas responder y ver tu historial de participación en las acciones d{companyArticle} {companyName} de forma segura.',
          private: 'privado',
          encrypted: 'cifrado',
        },
        _table: {
          _tabs: {
            research: 'Encuestas',
            archive: 'Historial',
          },
          _status: {
            start: 'No empezado',
            continue: 'En proceso',
            home: 'Finalizado',
            answered: 'Respondida',
          },
          _action: {
            start: 'Comenzar',
            continue: 'Continuar',
          },
          _empty: {
            title: 'No hay encuestas disponibles',
            message: 'Mientras no tenemos ninguna encuesta para {action}, ¿qué tal un café?',
            answer: 'que respondas',
            show: 'mostrar en tu historial',
          },
          answered: 'Respondida',
          finishesAt: 'Finaliza el día',
          answeredAt: 'Respondida el día',
          finishedAt: 'Finalizada el día',
        },
        title: 'Página principal',
      },
      _research: {
        _start: {
          _message: {
            title: 'Mensaje d{companyArticle} {companyName}',
            _msg1: {
              title: 'Tiempo de llenado del formulario',
              message:
                'Dedica un tiempo de calidad para responder la encuesta. El formulario tiene {count} y toma en promedio {tta} para completarse.',
              answer: 'pregunta | preguntas',
              minute: 'minuto | minutos',
            },
            _msg2: {
              title: 'Seguridad y privacidad',
              message:
                'Todos los datos recopilados en este formulario son confidenciales y seguros. {companyArticle} {companyName} {never} tendrá acceso a tus respuestas de forma individual.',
              never: 'nunca',
            },
            _msg3: {
              title: 'Procesamiento de datos',
              message:
                'Al comenzar la encuesta, aceptas que Winx almacene y procese tus respuestas de forma anonimizada.',
            },
          },
          _action: {
            deny: 'Ahora no',
            accept: 'Iniciar la encuesta',
          },
          welcome:
            'Tenemos un breve mensaje d{companyArticle} {companyName} sobre el objetivo de esta acción y información de Winx sobre tu privacidad y seguridad al responder el formulario.',
          title: 'Iniciar la encuesta',
        },
        _finish: {
          _message: {
            title: 'Contribuye con Winx',
            message:
              'En Winx estamos obsesionados con nuestros usuarios y clientes. Por eso, nos encantaría saber cómo fue tu experiencia utilizando nuestra plataforma.',
            _msg1: {
              title: 'Corre la voz',
              message:
                'Invita a tus colegas de trabajo a participar en las encuestas. Cuantas más personas se unan a las acciones d{companyArticle} {companyName}, más efectivas serán las mejoras en tu ambiente de trabajo.',
            },
            _msg2: {
              title: 'Vuelve pronto',
              message:
                'Este espacio es tuyo para que puedas contribuir continuamente en las encuestas. Mantente atento a las comunicaciones futuras y sigue respondiendo nuestros formularios y participando en las acciones promovidas.',
            },
          },
          _action: {
            home: 'Ir a la pantalla principal',
            rating: 'Evaluar experiencia',
            back: 'volver',
            send: 'Enviar evaluación',
          },
          _form: {
            title: 'Cuéntanos',
            answer: 'Deja tu opinión sincera sobre cómo fue utilizar nuestra plataforma.',
            label: 'Deja tus comentarios (opcional)',
            dislike: 'No me gustó',
            like: 'Me encantó Winx',
            done: '¡Evaluación completada!',
            message:
              'Muchas gracias por evaluar a Winx. Tu contribución es muy importante para mejorar nuestras soluciones.',
          },
          _footer: {
            title: 'Aún tienes encuestas por responder',
          },
          welcome: 'Tus respuestas resultarán en acciones concretas para mejorar tu ambiente de trabajo.',
          title: 'Nos alegra mucho tu contribución',
        },
        _interaction: {
          _message: {
            title: 'Recibe Mis Recomendaciones Personalizadas',
            p1:
              '¡Gracias por responder al formulario! Basándome en tus respuestas, tengo varias recomendaciones que te ayudarán en tu autodesarrollo profesional.',
            p2:
              'Si deseas recibir mis mensajes, por favor, ingresa tu mejor dirección de correo electrónico en el campo a continuación. Prometo que tu correo electrónico será utilizado solo para este propósito y no será compartido con nadie. Yo, Winnie, no envío SPAM ni publicidad.',
            label: 'Tu mejor dirección de correo electrónico',
            _msg1: {
              title: 'Corre la voz',
              message:
                'Invita a tus colegas de trabajo a participar en las encuestas. Cuantas más personas se unan a las acciones d{companyArticle} {companyName}, más efectivas serán las mejoras en tu ambiente de trabajo.',
            },
            _msg2: {
              title: 'Vuelve pronto',
              message:
                'Este espacio es tuyo para que puedas contribuir continuamente en las encuestas. Mantente atento a las comunicaciones futuras y sigue respondiendo nuestros formularios y participando en las acciones promovidas.',
            },
          },
          _action: {
            back: 'Ahora no',
            send: 'Quiero recibir',
          },
          welcome: 'Tus respuestas resultarán en acciones concretas para mejorar tu ambiente de trabajo.',
          title: 'Nos alegra mucho tu contribución',
        },
        _denied: {
          _message: {
            _msg1: {
              title: 'Da voz a tu opinión',
              message:
                'Esta encuesta es una de las mejores oportunidades que tienes para compartir de forma anónima tus experiencias, opiniones y sugerencias sobre tu trabajo.',
            },
            _msg2: {
              title: 'Plazo para completar',
              message:
                'Encuentra un momento de tranquilidad en tu rutina de trabajo. Puedes responder hasta el día {finishesAt}.',
            },
            _msg3: {
              title: 'Seguridad y privacidad',
              message:
                'Todos los datos recopilados en este formulario son confidenciales y seguros. {companyArticle} {companyName} {never} tendrá acceso a tus respuestas de forma individual.',
              never: 'nunca',
            },
          },
          _action: {
            home: 'Volver al inicio',
          },
          welcome:
            'Entiendo que este puede no ser el momento ideal para que participes en esta encuesta, pero siéntete libre de volver y responder más tarde.',
          title: 'Encuesta no respondida',
        },
        _form: {
          _instruction: {
            likert:
              'Considerando la opción {disagree} como el peor escenario y {agree} como el mejor, elige de la lista de expresiones la opción que mejor represente tu opinión sobre la afirmación realizada.',
            nps:
              'Considerando {zero} como el peor escenario y {ten} como el mejor, elige de la lista la opción que mejor represente tu opinión sobre la pregunta realizada.',
            radio:
              'Basado en tu experiencia en el trabajo, elige la opción a continuación que mejor represente tu respuesta a la afirmación o pregunta realizada.',
            checkbox:
              'Considerando tu experiencia en el trabajo, elige las opciones a continuación que mejor representen tu respuesta a la afirmación o pregunta realizada.',
            textarea:
              'Esta es una pregunta de respuesta disertativa (escrita). Utiliza el campo a continuación para hablar abiertamente sobre la pregunta realizada. Recuerda que este es un entorno anónimo y no serás identificado.',
            select:
              'Considerando tu experiencia en el trabajo, comienza a escribir en el campo de búsqueda a continuación el nombre de la persona que mejor represente la respuesta a la pregunta realizada. Añade una indicación a la vez.',
            demographic:
              'Seleccione la opción que mejor se aplique a usted. Esta es una respuesta confidencial y anónima, y su participación es fundamental para garantizar la representatividad de los datos.',
          },
          _likert: {
            agree: 'Totalmente de acuerdo',
            partialAgree: 'Parcialmente de acuerdo',
            neutral: 'Ni de acuerdo ni en desacuerdo',
            partialDisagree: 'Parcialmente en desacuerdo',
            disagree: 'Totalmente en desacuerdo',
            na: 'No aplica',
          },
          _nps: {
            zero: '0 (cero)',
            ten: '10 (diez)',
          },
          _comment: {
            label: 'Si lo deseas, deja un comentario que nos ayude a entender tu elección:',
            placeholder: 'Haz tu comentario (opcional)',
          },
          _select: {
            label: 'Utiliza el campo de arriba para buscar',
            disable: 'Límite de indicaciones alcanzado',
          },
          _textarea: {
            label: 'Escribe tu respuesta aquí',
          },
          _header: {
            title: 'Pregunta {number} de {total}',
          },
          _footer: {
            _action: {
              prev: 'Pregunta anterior',
              next: 'Próxima pregunta',
              skip: 'Prefiero no responder',
              answer: 'Responder',
              back: 'Volver',
              finish: 'Finalizar',
            },
            confidential: 'Tus respuestas son confidenciales',
          },
          _modal: {
            title: 'Hemos llegado al final',
            message:
              'Esta es la última pregunta de este formulario. Al hacer clic en {finish}, terminaremos tu participación en esta encuesta y tus respuestas serán almacenadas de forma anonimizada por Winx.',
          },
          title: 'Responder encuesta',
        },
      },
    },
  };
});
