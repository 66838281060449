import { default as _91form_93MAc3BTGQ6AMeta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedBO1zgdQbD3Meta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishNdfo5taerLMeta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as interactionOPVqf81ifVMeta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/interaction.vue?macro=true";
import { default as startcfvkyLfXkkMeta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93GLo0n8ULvjMeta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexj97nzIxT8AMeta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginBKXVtyc5VCMeta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectRVSs1u8qoUMeta } from "/codebuild/output/src3926111796/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_93GLo0n8ULvjMeta?.name ?? "research",
    path: _91_91research_93_93GLo0n8ULvjMeta?.path ?? "/:research?",
    meta: _91_91research_93_93GLo0n8ULvjMeta || {},
    alias: _91_91research_93_93GLo0n8ULvjMeta?.alias || [],
    redirect: _91_91research_93_93GLo0n8ULvjMeta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93MAc3BTGQ6AMeta?.name ?? "research-form",
    path: _91form_93MAc3BTGQ6AMeta?.path ?? ":form()",
    meta: _91form_93MAc3BTGQ6AMeta || {},
    alias: _91form_93MAc3BTGQ6AMeta?.alias || [],
    redirect: _91form_93MAc3BTGQ6AMeta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: deniedBO1zgdQbD3Meta?.name ?? "research-denied",
    path: deniedBO1zgdQbD3Meta?.path ?? "denied",
    meta: deniedBO1zgdQbD3Meta || {},
    alias: deniedBO1zgdQbD3Meta?.alias || [],
    redirect: deniedBO1zgdQbD3Meta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finishNdfo5taerLMeta?.name ?? "research-finish",
    path: finishNdfo5taerLMeta?.path ?? "finish",
    meta: finishNdfo5taerLMeta || {},
    alias: finishNdfo5taerLMeta?.alias || [],
    redirect: finishNdfo5taerLMeta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: interactionOPVqf81ifVMeta?.name ?? "research-interaction",
    path: interactionOPVqf81ifVMeta?.path ?? "interaction",
    meta: interactionOPVqf81ifVMeta || {},
    alias: interactionOPVqf81ifVMeta?.alias || [],
    redirect: interactionOPVqf81ifVMeta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/interaction.vue").then(m => m.default || m)
  },
  {
    name: startcfvkyLfXkkMeta?.name ?? "research-start",
    path: startcfvkyLfXkkMeta?.path ?? "start",
    meta: startcfvkyLfXkkMeta || {},
    alias: startcfvkyLfXkkMeta?.alias || [],
    redirect: startcfvkyLfXkkMeta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexj97nzIxT8AMeta?.name ?? "index",
    path: indexj97nzIxT8AMeta?.path ?? "/",
    meta: indexj97nzIxT8AMeta || {},
    alias: indexj97nzIxT8AMeta?.alias || [],
    redirect: indexj97nzIxT8AMeta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBKXVtyc5VCMeta?.name ?? "login",
    path: loginBKXVtyc5VCMeta?.path ?? "/login",
    meta: loginBKXVtyc5VCMeta || {},
    alias: loginBKXVtyc5VCMeta?.alias || [],
    redirect: loginBKXVtyc5VCMeta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectRVSs1u8qoUMeta?.name ?? "redirect",
    path: redirectRVSs1u8qoUMeta?.path ?? "/redirect",
    meta: redirectRVSs1u8qoUMeta || {},
    alias: redirectRVSs1u8qoUMeta?.alias || [],
    redirect: redirectRVSs1u8qoUMeta?.redirect,
    component: () => import("/codebuild/output/src3926111796/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]